body {
    background: #0d9ed1 url(./img/bg.png);
    font-style: italic;
    font-family: Georgia, serif;
    color: #f2f2f2;
    -webkit-font-smoothing: antialiased;
    height: 400px;
}

a {
    color: #e4e4e4;
}

.background {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    background-size: cover;
    transition: opacity 1s;
}

.wrapper {
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    flex-direction: column;
}

header {
    z-index: 1;
    min-width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    text-shadow: 1px 1px 5px black;
}

main {
    min-width: 100%;
    flex: 2;
}

.warmth {
    min-height: 100px;
    position: relative;
    margin: 0;
    font-size: 80px;
    font-weight: bold;
    display: block;
    text-shadow: 1px 1px 5px black;
}

.degree {
    color: #000;
    font-size: 19px;
    font-weight: normal;
    position: relative;
    width: 42px;
    height: 50px;
    background-color: white;
    display: block;
    border-radius: 25px;
    padding: 0 0 0 8px;
    margin: 0 0 0 -25px;
    text-align: center;
    line-height: 45px;
    left: 50%;
    transition: opacity 1s;
    box-shadow: 1px 1px 5px black;
}

.bottom {
    position: fixed;
    bottom: 0px;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    padding: 0 0 10px 0;
    text-align: center;
    font-size: 11px;
}

@media screen and (max-width: 480px) {
    .wrapper {
        padding: 20px 5px 0 5px;
        min-height: 400px;
    }

    .title {
        margin: 0;
        padding: 20px 0 0 0;
        font-size: 21px;
    }

    .warmth {
        margin: 0;
        padding: 0;
        top: 55px;
        font-size: 50px;
    }

    #icon {
        margin: 100px 0 0 0;
    }

    .bottom {
        font-size: 11px;
        position: fixed;
        bottom: 5px;
        left: 50%;
        width: 320px;
        margin-left: -160px;
    }

    .social {
        display: none;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    /* iPhone retina afbeeldingen */
}

@media screen and (max-width: 800px) {
    .wrapper {
        padding: 0;
    }

    .title {
        font-size: 21px;
    }

    .bottom {
    }
}

@media screen and (max-device-width: 1024px) and (orientation: portrait) {
    /* For portrait layouts only */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
    .wrapper {
        padding: 0;
    }

    .warmth {
        top: 40px;
        font-size: 50px;
    }
}
